/* SignIn SignOut buttons and SignIn page logo styles */
.signInButton {
    display:inline-flex; 
    font-family: Roboto;
    align-items:center; 
    cursor:pointer;
    color:black; 
    padding:7px 10px; 
    background-color:white; 
    border:1px solid rgb(120, 120, 120);
    border-radius: 4px;
}

.signOutButton {
    display:inline-flex; 
    font-family: Roboto;
    align-items:center; 
    cursor:pointer;
    color:black; 
    padding:7px 10px; 
    background-color:white; 
    border:1px solid rgb(120, 120, 120);
    border-radius: 4px;
    opacity: 0.4;
}

.signOutButton:hover{
    color:white; 
    background-color:#D64937; 
    opacity: 1.0;
}


.signOutButton > img {
    position: absolute;
}

.signOutButton > img:nth-child(1){ /* first <img> child of <div> */
    filter: alpha(opacity=100); /* IE stuff */
    opacity: 1;
    z-index: 2;
}


.signOutButton > img:nth-child(2){ /* second <img> child of <div> */
    filter: alpha(opacity=0); /* IE stuff */
    opacity: 0;
    z-index: 1;
}

.signOutButton:hover > img:nth-child(1){ 
    /* display: none; */
    filter: alpha(opacity=0);
    opacity: 0;
    z-index: 1;
}


.signOutButton:hover > img:nth-child(2){ 
    filter: alpha(opacity=100);
    opacity: 1;
    z-index: 2;
}

.logos{
    height: 6vh;
    margin-right: 10px;
}
